import React, { useState, useEffect } from 'react';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import { Box, Grid, Paper, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button } from '@mui/material'
import { useAuth0 } from "@auth0/auth0-react";

const Api = () => {
  // Replace with actual data fetching or state management logic
  const [apiKey, setApiKey] = useState('YOUR_API_KEY');
  const [remainingTokens, setRemainingTokens] = useState(0);
  const { user, isAuthenticated } = useAuth0();

  useEffect(() => {
    if (isAuthenticated && user) {
      // Call the backend API to create or fetch the API key and tokens
      createOrFetchApiKey(user.email);
    }
  }, [isAuthenticated, user]);



  const createOrFetchApiKey = async (email) => {
    try {
      const response = await fetch('https://fbdebate-a4xncz5jja-uc.a.run.app/create_api_key', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        const data = await response.json();
        setRemainingTokens(data.tokens);
        setApiKey(data.api_key)
      } else {
        console.error('Failed to create or fetch API key');
      }
    } catch (error) {
      console.error('Error creating or fetching API key:', error);
    }
  };


  return (
    <Box sx={{ padding: 2 }}>
        <br></br>
        <br></br>
        <br></br>

        <Grid container spacing={2} justifyContent="center">
        {/* Left Spacer Column */}
        <Grid item xs={12} md={1}></Grid>

        {/* Center Content Box */}
        <Grid item xs={12} md={10}>
          <Paper elevation={3} sx={{ padding: 2 }}>
            <Typography variant="h6" gutterBottom>
              API Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography variant="body1">
                  <strong>API Key:</strong> {apiKey}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body1">
                  <strong>Remaining Credits:</strong> {remainingTokens}
                </Typography>
              </Grid>
            </Grid>
            <br/>
            <a 
              href="https://forms.gle/K5pytik4AMvuQMQA8" 
              style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }}
              target="_blank" // Opens the link in a new tab
              rel="noopener noreferrer"
            >
              Click here to request for more credits
            </a>
            {/* Additional Details */}
            <Typography variant="h6" sx={{ marginTop: 2 }}>
              Model Versions and Token Usage
            </Typography>
            <TableContainer component={Paper} sx={{ marginTop: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell><strong>Version</strong></TableCell>
                    <TableCell><strong>Accuracy</strong></TableCell>
                    <TableCell><strong>Credit Usage per Request</strong></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>v3</TableCell>
                    <TableCell>92%</TableCell>
                    <TableCell>1 Credit</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>v2</TableCell>
                    <TableCell>85%</TableCell>
                    <TableCell>0.5 Credit</TableCell>
                  </TableRow>
                </TableBody>
              </Table>

              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell><strong>Endpoint</strong></TableCell>
                    <TableCell><strong>-</strong></TableCell>
                    <TableCell><strong>Token Usage per Request</strong></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Claim Extraction</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>1 Credit per 1,000,000 characters processed</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            {/* Explanation of Token Usage */}
            <Typography variant="body2" sx={{ marginTop: 2 }}>
              Each API request for fact-checking will require a specific number of credits depending on the model chosen:
              <ul>
                <li>Version v3 (92% accuracy) requires 1 credit per request.</li>
                <li>Version v2 (85% accuracy) requires 0.5 credit per request.</li>
              </ul>
              Choose the version that best suits your accuracy needs and token availability.
            </Typography>
          </Paper>
        </Grid>
        {/* Right Spacer Column */}
        <Grid item xs={12} md={1}></Grid>
      </Grid>

      {/* Swagger UI */}
      <SwaggerUI url={`${process.env.PUBLIC_URL}/swagger.yaml`} />
    </Box>
  );
};

export default Api;
